<template>
  <details class="popup" ref="details" v-on:toggle="popupToggled()">
    <summary>
      <slot name="handle"></slot>
    </summary>
    <div   :class="$store.state.appConfig.layout.skin == 'light' ? 'content-dialog' : 'content-dialog-dark'">
      <slot name="content">Put your contents here</slot>
    </div>
  </details>
</template>
<script>
export default {
  created(){
  },
  methods: {
    open() {
      this.$refs.details.setAttribute("open", "");
    },
    close() {
      this.$refs.details.removeAttribute("open");
    },
    popupToggled() {
      const isOpen = this.$refs.details.getAttribute("open") !== null ? true : false;
      this.$emit("popup-toggled", isOpen);
    },
  },
};
</script>
<style>
/* Experimental : Details implementation */
 details.popup summary {
  outline: none;
  cursor: pointer;
  display: inline-block;
}

details.popup summary::-webkit-details-marker {
  display: none;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

details.popup .content-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  animation: fadein 200ms ease-in-out;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 80px);
  max-width: 500px;
  width: calc(100% - 80px);
  overflow-y: auto;
  z-index: 999;
  color: #000;
  background-color: #fff;
  padding: 20px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

details.popup .content-dialog-dark {
  position: fixed;
  top: 50%;
  left: 50%;
  animation: fadein 200ms ease-in-out;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 80px);
  max-width: 500px;
  width: calc(100% - 80px);
  overflow-y: auto;
  z-index: 999;
  color: #000;
  background-color: #283046;
  padding: 20px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

details[open].popup summary:before {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: fadein 200ms ease-in-out;
  cursor: default;
  content: "";
  z-index: 999;
  background: rgba(27, 31, 35, 0.5);
}
</style>
