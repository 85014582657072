<template>
  <DetailsPopup ref="restoreListpopup" v-show="activeBoard">
    <template v-slot:handle>
      <span class="nav-item btn btn-md btn-app mr-2"
        >Archived Lists ({{ archivedlistProject.length }})</span
      >
    </template>
    <template v-slot:content>
      <h4>Restore your lists for {{ boardName }}</h4>
      <ul class="list-group mb-2">
        <li
          v-for="list in archivedlistProject"
          :key="list.id"
          class="list-group-item d-flex justify-content-between align-items-center"
        >
          <label>
            {{ list.name }} <small>({{ list.items.length }} items)</small>
          </label>
          <button
            class="btn btn-md btn-success"
            @click="handleTaskListRestore(list)"
          >
            Restore
          </button>
        </li>
        <li class="list-group-item" v-show="archivedlistProject.length == 0">
          Nothing to restore
        </li>
      </ul>
      <button class="btn btn-md btn-app btn-danger" @click="close">
        Close
      </button>
    </template>
  </DetailsPopup>
</template>
<script>
import DetailsPopup from "@/components/Details/DetailsPopup";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    DetailsPopup,
  },
  data() {
    return {
      archivedlistProject: [],
    };
  },
  computed: {
    ...mapGetters({
      activeBoard: "getBoard",
      archivedLists: "archivedLists",
    }),
    boardName() {
      return this.activeBoard ? this.activeBoard.name : "";
    },
  },
  async created() {
    await this.setInActiveTaskList({
      board: null,
    });
    setTimeout(() => {
      this.archivedlistProject=[]
      console.log("this", this.$store.getters.getBoard.lists);
      this.$store.getters.getBoard.lists.forEach((element) => {
        if (element.status == "inactive") {
          this.archivedlistProject.push(element);
        }
      });
    }, 300);
  },
  methods: {
    ...mapActions({
      restoreTaskList: "restoreTaskList",
      setInActiveTaskList: "setInActiveTaskList",
    }),
    handleTaskListRestore(list) {
      console.log(list, this.activeBoard);
      list.status = "active";
      this.restoreTaskList({
        boardId: this.activeBoard._id,
        listId: list._id,
        newList: list,
      });
    },
    close() {
      this.$refs.restoreListpopup.close();
    },
  },
};
</script>
