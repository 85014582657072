<template>
  <DetailsPopup
    ref="newBoardPopup"
    v-show="!this.activeBoard"
    @popup-toggled="handlePopupToggled"
  >
    <template v-slot:handle>
      <span class="nav-item btn btn-md btn-app mr-2">+ New Project</span>
    </template>
    <template v-slot:content>
      <form>
        <h4>{{ heading }}</h4>
        <input
          name="boardName"
          type="text"
          class="form-control my-1"
          v-model.trim="boardForm.name"
          data-vv-as="Project Name"
          placeholder="Enter your project name"
        />
        <textarea
          name="boardDescription"
          rows="2"
          class="form-control my-1"
          v-model.trim="boardForm.description"
          data-vv-as="project Description"
          placeholder="Enter your project description"
        ></textarea>
        <button
          class="save-project btn btn-md btn-app mt-2"
          @click.prevent="handleSaveBoard"
        >
          Save Project
        </button>
      </form>
    </template>
  </DetailsPopup>
</template>
<script>
import DetailsPopup from "@/components/Details/DetailsPopup";
import { mapGetters, mapActions } from "vuex";
import { Bus } from "@/utils/bus";
export default {
  components: {
    DetailsPopup,
  },
  data() {
    return {
      boardForm: {
        id: "",
        name: "",
        description: "",
      },
    };
  },
  mounted() {
    Bus.$on("taskboard-editing", this.handleTaskBoardEditing);
    Bus.$on("meeting-editing", this.handleMeetingEditing);
  },
  computed: {
    ...mapGetters({
      activeBoard: "activeBoard",
    }),
    heading() {
      return this.boardForm.id
        ? "Update project information"
        : "Create new project";
    },
  },
  methods: {
    ...mapActions({
      saveTaskBoard: "saveTaskBoard",
    }),
    handleMeetingEditing(payload) {
      this.boardForm.id = payload.id;
      this.boardForm.name = payload.name;
      this.boardForm.description = payload.description;
      this.$refs.newBoardPopup.open();
      console.log("hi am new boardpopup", this.$refs.newBoardPopup);
    },
    handleTaskBoardEditing(board) {
      this.boardForm.id = board.id;
      this.boardForm.name = board.name;
      this.boardForm.description = board.description;
      this.$refs.newBoardPopup.open();
    },
    handlePopupToggled(isOpen) {
      if (!isOpen) {
        this.boardForm.id = 0;
        this.boardForm.name = "";
        this.boardForm.description = "";
      }
    },
    handleSaveBoard() {
      this.saveTaskBoard({
        //id: this.boardForm.id,
        name: this.boardForm.name,
        description: this.boardForm.description,
      });
      this.$refs.newBoardPopup.close();
    },
  },
};
</script>
<style scoped>
.nav-item {
  background: #9600ff;
  color: #f0f0f0;
  border-radius: 20px;
}
.save-project {
  background: #9600ff;
  color: #f0f0f0;
  border-radius: 20px;
}
</style>

