<template>
  <div class="container-fluid">
    <!-- <AppHeader></AppHeader> -->
    <AppLoadingIndicator></AppLoadingIndicator>
    <!-- <b-card-actions
      action-collapse
      title="Active Projects"
      class="cards-space-tb"
    > -->
    <p v-show="this.unarchivedBoards.length === 0">
      {{ $t("No_Project_Yet_Add_Project") }}
    </p>
    <div class="row" v-show="this.unarchivedBoards.length > 0">
      <div class="col">
        <h4 class="my-2">{{ $t("Active_Projects") }}</h4>
      </div>
    </div>
    <div class="row equal">
      <div
        class="col-xs-12 col-sm-6 col-md-3 d-flex pb-3"
        v-for="board in unarchivedBoards"
        :key="board._id"
      >
        <div class="card w-100">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h5 class="card-title flex-nowrap">{{ board.name }}</h5>
              <span @click="handleTaskBoardEditing(board)">...</span>
            </div>
            <h6><feather-icon icon="AlignCenterIcon" /> <b>Description</b></h6>
            <p class="card-text">{{ board.description }}</p>
          </div>
          <div class="card-footer bg-transparent">
            <router-link
              class="btn btn-md btn-app mr-1 mb-1"
              style="background-color: #9600ff; color: #fff"
              :to="{ name: 'project-details', params: { id: board._id } }"
            >
              <feather-icon icon="EyeIcon" />
              {{ $t("View") }}</router-link
            >
            <b-button
              class="btn btn-md btn-danger mb-1"
              @click="handleArchiveTaskBoard(board)"
            >
              <feather-icon icon="ArchiveIcon" />
              {{ $t("Archive") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- </b-card-actions> -->
    <!-- <b-card-actions
      action-collapse
      title="Archived Projects"
      v-show="this.archivedBoards.length > 0"
    > -->
    <div class="row" v-show="this.archivedBoards.length > 0">
      <div class="col">
        <h4 class="my-3">Archived Projects</h4>
      </div>
    </div>
    <div class="row equal" v-show="this.archivedBoards.length > 0">
      <div
        class="col-xs-12 col-sm-6 col-md-3 d-flex pb-3"
        v-for="board in archivedBoards"
        :key="board._id"
      >
        <div class="card w-100">
          <div class="card-body">
            <h5 class="card-title flex-nowrap">{{ board.name }}</h5>
            <p class="card-text">{{ board.description }}</p>
          </div>
          <div class="card-footer bg-transparent text-center">
            <button
              class="btn btn-md btn-success"
              @click="handleRestoreTaskBoard(board)"
            >
              {{ $t("Restore") }}
            </button>
            <button
              style="margin-left: 10px"
              class="btn btn-md btn-danger"
              @click="handleDeleteTaskBoard(board)"
            >
              {{ $t("Delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- </b-card-actions> -->
  </div>
</template>
<script>
import AppHeader from "@/components/AppHeader";
import AppLoadingIndicator from "@/components/AppLoadingIndicator";
import { mapGetters, mapActions } from "vuex";
import { Bus } from "@/utils/bus";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BButton, BCard } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ToastificationContent,
    AppHeader,
    AppLoadingIndicator,
    BCardActions,
    BButton,
    BCard,
  },
  beforeCreate() {
    this.$store.dispatch("fetchProjects");
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
      unarchivedBoards: "unarchivedBoards",
      archivedBoards: "archivedBoards",
    }),
  },
  methods: {
    ...mapActions({
      setActiveTaskBoard: "setActiveTaskBoard",
      setInActiveTaskBoard: "setInActiveTaskBoard",
      archiveTaskBoard: "archiveTaskBoard",
      restoreTaskBoard: "restoreTaskBoard",
      deleteTaskBoard: "deleteTaskBoard",
    }),
    handleTaskBoardEditing(board) {
      Bus.$emit("taskboard-editing", board);
    },
    handleArchiveTaskBoard(board) {
      board.status = "inactive";
      this.archiveTaskBoard({ board });
    },
    handleRestoreTaskBoard(board) {
      board.status = "active";
      this.restoreTaskBoard({ board });
    },
    handleDeleteTaskBoard(board) {
      try {
        this.deleteTaskBoard({ board });
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Project deleted ",
            icon: "EditIcon",
            variant: "success",
          },
        });
      } catch {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error during delete",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },
  },
  async created() {
    await this.setActiveTaskBoard({
      board: null,
    });
    await this.setInActiveTaskBoard({
      board: null,
    });
    console.log(this);
    // this.archivedBoards =[]
    // this.unarchivedBoards.forEach(element => {
    //   if(element.status=="inactive"){
    //     console.log("aaaaa")
    //     this.archivedBoards.push(element)
    //   }
    // });
    // console.log( this.archivedBoards)
  },
};
</script>
<style >
.cards-space-tb {
  margin-top: 3%;
  margin-bottom: 3%;
}
.board-item {
  background-color: #fff !important;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}
</style>