<template>
  <DetailsPopup
    ref="newListPopup"
    v-show="this.activeBoard"
    @popup-toggled="handlePopupToggled"
  >
    <template v-slot:handle>
      <span class="nav-item btn btn-md btn-app mr-2">+ New List</span>
    </template>
    <template v-slot:content>
      <form>
        <h4>{{ heading }}</h4>
        <input
          name="listName"
          type="text"
          class="form-control my-1"
          v-model="listForm.name"
          data-vv-as="List Name"
          placeholder="Enter your list name"
        />
        <button
          class="save-list btn btn-md btn-app mt-2"
          @click.prevent="handleTaskListSave(listForm)"
        >
          Save List
        </button>
      </form>
    </template>
  </DetailsPopup>
</template>
<script>
import DetailsPopup from "@/components/Details/DetailsPopup";
import { mapGetters, mapActions } from "vuex";
import { Bus } from "@/utils/bus";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    DetailsPopup,
    ToastificationContent
  },
  data() {
    return {
      stateModal:false,
      listForm: {
        id: "",
        name: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      activeBoard: "activeBoard",
    }),
    boardName() {
      return this.activeBoard ? this.activeBoard.name : "";
    },
    heading() {
      console.log("testing", this.listForm);
        if (this.listForm.id || this.listForm.name){
                this.stateModal=this.listForm
                return "Update list name"
              }else{
                this.stateModal=false
                return "Create new list"
              }
     
      // return this.listForm.id || this.listForm.name
      //   ? "Update list name"
      //   : "Create new list";
    },
  },
  mounted() {
    Bus.$on("tasklist-editing", this.handleTaskListEditing);
  },
  methods: {
    ...mapActions({
      saveTaskList: "saveTaskList",
      updateTaskList: "updateTaskList",
    }),
    handlePopupToggled(isOpen) {
      if (!isOpen) {
        this.listForm.id = 0;
        this.listForm.name = "";
      }
    },
    handleTaskListEditing(list) {
      console.log("list", list);
      this.listForm.id = list._id;
      this.listForm.name = list.name;
      this.$refs.newListPopup.open();
    },
    handleTaskListSave(listForm) {

      if (this.stateModal && this.stateModal.id!=0 ) {
        // axios
        // .put("https://backendapinodejs.timecheckit.com/api/projects/"+this.$route.params.id+"/lists/"+this.stateModal.id, {"name":this.stateModal.name})
        // .then((response) => {
        //   console.log(response.data);
        // })
        // .catch((error) => {
        //   this.errorMessage = error.message;
        // });
        try{
          this.updateTaskList({
          boardId: this.activeBoard._id,
          boardList: this.stateModal,
        });
       
        }catch{
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Please reload the page",
                icon: "EditIcon",
                variant: "danger",
              },
            });
        }
         this.stateModal=false
      } else {
        this.saveTaskList({
          boardId: this.activeBoard._id,
          name: this.listForm.name,
        });
      }

      this.$refs.newListPopup.close();
    },
  },
};
</script>
<style scoped>
.nav-item {
  background: #9600ff;
  color: #f0f0f0;
  border-radius: 20px;
}
.save-list {
  background: #9600ff;
  color: #f0f0f0;
  border-radius: 20px;
}
</style>