<template>
  <nav class="navbar navbar-transparent bg-faded">
    <div class="navbar-brand color-primary">
      <label>
        <!-- <h2>
          <b-badge variant="primary" class="badge-top-section"
            >Projects List
          </b-badge>
        </h2> -->
        <span class="text-uppercase" v-show="this.activeBoard"
          >{{ boardName }}
        </span>
      </label>
    </div>
    <div class="progress-bar-project">
      

      <b-progress :max="max" class="progress-bar-info" v-b-modal.modal-progress>
       <b-progress-bar :value="value" :label="`${value}%`" variant="primary" />
      </b-progress>
     

      <b-modal id="modal-progress" centered hide-footer hide-header ref="my-modal-progress">
        <div>
          <h3>Project progress</h3>
        </div>
        <div>
          <div class="modal-progress-bar-value">
            <span class="modal-progress-bar">
              <span> 0</span>
              <b-form-input
                id="range-1"
                v-model="value"
                type="range"
                min="0"
                max="100"
              ></b-form-input>

              <span>100</span>
            </span>
            <span v-if="value">
              {{ value }}
            </span>
            <span v-else> 0 </span>
          </div>
        </div>
        <div>
          <button
            class="btn btn-md btn-app btn-primary"
            @click="handleUpdateBoard(activeBoard)"
          >
            save
          </button>
        </div>
      </b-modal>
    </div>
    <div class="d-flex justify-content-end" v-if="!isLoading">
      <TaskListRestore></TaskListRestore>
      <TaskListEdit></TaskListEdit>
      <TaskBoardEdit></TaskBoardEdit>
      <TaskListArchive></TaskListArchive>
    </div>
  </nav>
</template>
<script>
import { BFormInput } from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import TaskBoardEdit from "./Boards/TaskBoardEdit";
import TaskListEdit from "./Lists/TaskListEdit";
import TaskListRestore from "./Lists/TaskListRestore";
import TaskListArchive from "./Lists/TaskListArchive";
import { BBadge } from "bootstrap-vue";
import { BProgress, BProgressBar } from "bootstrap-vue";

export default {
  data() {
    return {
      max: 100,
      value: "0",
    };
  },
  components: {
    BFormInput,
    BProgress,
    BProgressBar,
    TaskBoardEdit,
    TaskListEdit,
    TaskListRestore,
    TaskListArchive,
    BBadge,
  },
  created() {
    if (this.activeBoard) {
      if (this.activeBoard.progress) {
        this.value = this.activeBoard.progress.toString();
      }
    }
  },
  computed: {
    ...mapGetters({
      activeBoard: "activeBoard",
      isLoading: "isLoading",
    }),
    boardName() {
      return this.activeBoard ? this.activeBoard.name : "";
    },
  },
  methods: {
     ...mapActions({
      updateBoard: "archiveTaskBoard",
    }),
    handleUpdateBoard(board) {
      board.progress = parseInt(this.value)
      this.updateBoard({ board });
      this.hideModal()
    },
      hideModal() {
        this.$refs['my-modal-progress'].hide()
      },
  },
};
</script>
<style>
.color-primary label {
  color: #5e5873 !important;
  /* color: #9600ff!important; */
  font-weight: 500;
  font-size: 1.285rem;
}
.progress-bar-project {
  width: 20%;
}
.progress-bar-project .progress-bar-info {
  height: 20px;
}
.modal-progress-bar {
  width: 100%;
  display: inline-flex;
  margin-top: 7%;
}
.modal-progress-bar span {
  padding: 0% 2%;
}
.modal-progress-bar-value {
  padding-bottom: 7%;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .progress-bar-project {
    margin: 5% 10% 10% 10%;
    width: 80%;
  }
}
</style>